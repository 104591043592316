import { SVG_IMAGES } from "../utils/constants/assets";
import { isNotEmpty } from "../utils/globalFunctions";
interface InlineErrorsProps {
  errors?: string[] | string;
}
const ErrorItem = ({ text }: { text: string }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
      <img className="error-icon" src={SVG_IMAGES.error_icon} />
      <span className="error-text">{text || ""}</span>
    </div>
  );
};
const InlineErrors = (props: InlineErrorsProps) => {
  return (
    <div
      style={{
        marginTop: -10,
        marginBottom: 15,
      }}
    >
      {typeof props?.errors === "string" ? (
        isNotEmpty(props?.errors) ? (
          <ErrorItem text={props?.errors} />
        ) : null
      ) : (
        props?.errors?.map?.((item: any, index) => {
          return <ErrorItem key={`error_${index}`} text={item} />;
        })
      )}
    </div>
  );
};
export default InlineErrors;
