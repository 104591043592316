import * as yup from "yup";
import { STRING_CONSTANTS } from "./constants/stringConstants";

export const newSportValidationSchema = yup.object({
  sportRequested: yup
    .string()
    .trim()
    .required(STRING_CONSTANTS.required_validation("Sport"))
    .matches(/^[\w\-\s]+$/, STRING_CONSTANTS.new_sport_error("sport")),
  teamRequested: yup
    .string()
    .trim()
    .required(STRING_CONSTANTS.required_validation("Team"))
    .matches(/^[\w\-\s]+$/, STRING_CONSTANTS.new_sport_error("team")),
});
