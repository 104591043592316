import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import Cookies from "js-cookie";
import { ROUTES_CONSTANTS } from "../utils/constants/routesString";
import { APP_CONSTANTS } from "../utils/constants/appConstants";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export const PrivateRoute = ({ children }: any) => {
    const isAuthenticated = true;
    if (isAuthenticated) {
        return children
    }

    return <Navigate to="/" />
}

export default PrivateRoute;