export const COMMON_STYLES = {
  step_icon: {
    width: 24,
    height: 24,
  },
  input_icon: {
    position: "absolute",
    top: 0,
    left: 0,
    height: 24,
    width: 24,
    borderWidth: 0,
    backgroundColor: "transparent",
  } as React.CSSProperties,
  button: {
    height: 24,
    width: 24,
    position: "absolute",
    borderWidth: 0,
    top: 0,
    right: 0,
    backgroundColor: "transparent",
  } as React.CSSProperties,
};
