import { Ref, forwardRef, useState } from "react";
import { useController } from "react-hook-form";
import { InputProps } from "./types";
import { COMMON_STYLES } from "../../utils/constants/commonStyles";
import ImageIcon from "../ImageIcon";
import ISDPicker from "../ISDPicker";
// import { COLORS } from "../../utils/constants/colors";

const ControlledInput = forwardRef(
  (props: InputProps, ref: Ref<any> | undefined) => {
    const {
      name,
      label,
      rules,
      defaultValue,
      rightIcon,
      rightIconPress,
      // leftIcon,
      leftIconComponent,
      isError,
      errorMessage,
      blurCb,
      isIsd,
      extContainerStyle,
      leftIconContainerStyle,
      rightIconContainerStyle,
      inputStyle,
      onChangeCB,
      ISD,
      changeISD,
      ...inputProps
    } = props;

    const { field } = useController({ name, rules, defaultValue });
    const [isFocused, setIsFocused] = useState(false);

    let leftIconColor = "left-icon-gray";
    if (isFocused) {
      leftIconColor = "left-icon-focused";
    } else if (field.value) {
      // * non-empty input field
      if (isError) leftIconColor = "left-icon-error";
      else leftIconColor = "left-icon-white";
    } else if (isError) {
      leftIconColor = "left-icon-error";
    } else {
      leftIconColor = "left-icon-gray";
    }

    return (
      <div style={extContainerStyle}>
        <div className="mail-up">
          {/* <ImageIcon
            image={leftIcon}
            iconStyle={COMMON_STYLES.step_icon}
            wrapperStyle={{
              ...COMMON_STYLES.input_icon,
              ...leftIconContainerStyle,
            }}
            disabled={true}
          /> */}

          <label htmlFor={name}>
            <span
              className={leftIconColor}
              style={{ ...COMMON_STYLES.input_icon }}
            >
              {leftIconComponent}
            </span>
          </label>
          {isIsd ? (
            <ISDPicker
              isError={isError}
              value={ISD}
              updateValue={changeISD}
              leftIconColor={leftIconColor}
            />
          ) : null}
          <input
            id={name}
            name={name}
            className={`form-style ${isIsd ? "mrd" : ""} ${
              isError ? "error" : leftIconColor
            }`}
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.value);
              onChangeCB?.(e.target.value);
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            style={{
              color: isError ? "#EB5757" : isFocused ? "#43ff86" : "#e0e0e0",
              ...inputStyle,
            }}
            {...inputProps}
          />
          {field.value ? (
            <ImageIcon
              image={rightIcon}
              iconStyle={COMMON_STYLES.step_icon}
              onClick={rightIconPress}
              wrapperStyle={{
                ...COMMON_STYLES.button,
                ...rightIconContainerStyle,
              }}
            />
          ) : null}
        </div>
        {errorMessage?.()}
      </div>
    );
  }
);

export const Input = forwardRef(
  (props: InputProps, ref: Ref<any> | undefined) => {
    return <ControlledInput ref={ref} {...props} />;
  }
);
