import { useNavigate } from "react-router-dom";
import { SVG_IMAGES } from "../utils/constants/assets";
import { ROUTES_CONSTANTS } from "../utils/constants/routesString";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import * as yup from "yup";
import useYupValidationResolver from "../hooks/useYupValidationResolver";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { PersonalizedProfileForm } from "./types";
import { actionCreators } from "../actions/actionCreators";
import { checkForError, isNotEmpty } from "../utils/globalFunctions";
import { Input } from "../components/Input";
import InlineErrors from "../components/InlineErrors";
// import ImageCropModal from "../components/ImageCropper/ImageCropModal";
import { StoreType } from "../store/types";
import { getFullName } from "../utils/globalFunctions";
import { getUsernameSuggestions } from "../utils/globalFunctions";
import DisportStepsMenu from "../components/DisportStepsMenu";
import SvgPersonInputIcon from "../assets/SvgComponents/PersonInputIcon";
import ImageIcon from "../components/ImageIcon";
// import { COMMON_STYLES } from "../utils/constants/commonStyles";
import { COLORS } from "../utils/constants/colors";

const validationSchema = yup.object({
  username: yup
    .string()
    .required(STRING_CONSTANTS.required_validation("Username")),
});

function PersonaliseProfile() {
  const resolver = useYupValidationResolver(validationSchema);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorObj, setErrorObj] = useState<any>({
    username: "",
  });
  const userData = useSelector((state: StoreType) => state?.UserReducer?.user);
  const [suggestionList, setSuggestionList] = useState<string[]>([]);
  const { ...methods } = useForm<PersonalizedProfileForm>({
    mode: "onChange",
    defaultValues: {
      username: "",
      profileImage: "",
    },
    resolver,
  });

  const watchFields = methods?.watch(["username", "profileImage"]);
  const inputRef = useRef<any>(null);

  const saveAthleteFormData = (data: PersonalizedProfileForm) => {
    setErrorObj({
      username: "",
    });
    const payload = {
      username: data?.username,
      profileImage: data?.profileImage || userData?.profileImage,
    };
    dispatch(actionCreators.updateCreationData(payload));
  };

  const onSubmit: SubmitHandler<PersonalizedProfileForm> = (data) => {
    saveAthleteFormData(data);
    handleSportAndTeam();
  };

  const submitWithoutNavigation: SubmitHandler<PersonalizedProfileForm> = (
    data
  ) => {
    saveAthleteFormData(data);
  };

  const onError: SubmitErrorHandler<PersonalizedProfileForm> = (
    errors: any,
    e
  ) => {
    setErrorObj({ username: errors?.username?.message || "" });
  };

  const handleVerifyAthlete = () => {
    navigate(ROUTES_CONSTANTS.verify_athlete);
  };

  const handleSportAndTeam = () => {
    navigate(ROUTES_CONSTANTS.sport_and_team);
  };

  const checkForDisable = () => {
    if (!isNotEmpty(watchFields[0])) return true;
    return false;
  };

  const getSuggestedNames = () => {
    const name = getFullName(userData);
    const suggestions = getUsernameSuggestions(name);
    if (typeof suggestions === "object") setSuggestionList([...suggestions]);
    else setSuggestionList([]);
  };

  const handleChange = (value: string) => {
    if (value === "" && isNotEmpty(errorObj.username))
      setErrorObj({ ...errorObj, username: "" });
  };

  const getData = () => {
    if (isNotEmpty(userData?.username)) {
      methods?.setValue("username", userData?.username);
    }
    if (isNotEmpty(userData?.profileImage)) {
      methods.setValue("profileImage", userData?.profileImage);
    }

    getSuggestedNames();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="tabs-main">
      <DisportStepsMenu
        step={3}
        handleSaveAthleteFormData={methods.handleSubmit(
          submitWithoutNavigation,
          onError
        )}
      />

      <div className="nav-wd-right">
        <div className="wd-100">
          <div className="cross">
            <img src={SVG_IMAGES.close_icon} onClick={handleVerifyAthlete} />
          </div>
          <h2>
            {STRING_CONSTANTS.what_we_call_u}{" "}
            <span>{STRING_CONSTANTS.face_n_username}</span>
          </h2>

          <div className="form-m">
            <FormProvider {...methods}>
              <Input
                name="username"
                type="text"
                placeholder="your username"
                // leftIcon={SVG_IMAGES.person_input_icon}
                leftIconComponent={<SvgPersonInputIcon />}
                rightIcon={SVG_IMAGES.cross_small_icon}
                rightIconPress={() => {
                  methods.resetField("username");
                  handleChange("");
                }}
                isError={checkForError(errorObj, "username")}
                errorMessage={() => <InlineErrors errors={errorObj.username} />}
                onChangeCB={(value: string) => handleChange(value)}
              />
              <div className="suggestion-text">
                <h3>
                  {STRING_CONSTANTS.suggested_username}{" "}
                  <span>({STRING_CONSTANTS.ur_social_handles})</span>
                </h3>
                <ul>
                  {suggestionList?.map?.((item: string, index: number) => (
                    <button
                      key={`username_suggestion_${index}`}
                      className="clear-button"
                      onClick={() => methods?.setValue("username", item)}
                    >
                      <li
                        key={index}
                        style={
                          item === watchFields[0]
                            ? {
                                backgroundColor: COLORS.primary_theme,
                                color: COLORS.black,
                              }
                            : {}
                        }
                      >
                        {item}
                      </li>
                    </button>
                  ))}
                </ul>
              </div>

              <div className="profile-details">
                <h2>{STRING_CONSTANTS.add_pfp}</h2>
                <div className="dropzone relative">
                  <div
                    className="dropzone relative"
                    style={{
                      border: `${watchFields[1] ? 2 : 0}px solid white`,
                      overflow: "hidden",
                    }}
                  >
                    {watchFields[1] || userData?.profileImage ? (
                      <img
                        src={
                          typeof userData?.profileImage !== "string"
                            ? watchFields[1]
                              ? URL.createObjectURL(watchFields[1])
                              : URL.createObjectURL(userData?.profileImage)
                            : userData?.profileImage
                        }
                        alt="athlete profile"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          transformOrigin: "center",
                        }}
                      />
                    ) : (
                      <img
                        src={SVG_IMAGES.add_icon}
                        alt=""
                        className="upload-icon"
                        style={{ height: 22, width: 22 }}
                      />
                    )}

                    <input
                      ref={inputRef}
                      type="file"
                      className="upload-input"
                      accept="image/*"
                      onChange={(e) => {
                        if (e.target.files?.[0]) {
                          methods?.setValue(
                            "profileImage",
                            e.target.files?.[0]
                          );
                        }
                      }}
                    />
                  </div>
                  {watchFields[1] || userData?.profileImage ? (
                    <div
                      className="remove-profile-image"
                      style={{ borderRadius: 50 }}
                    >
                      <ImageIcon
                        image={SVG_IMAGES.remove_cross_icon}
                        wrapperStyle={{
                          backgroundColor: COLORS.error_color,
                          borderWidth: 0,
                          width: 28,
                          height: 28,
                        }}
                        onClick={() => {
                          methods?.setValue("profileImage", null);
                          dispatch(
                            actionCreators.updateCreationData({
                              profileImage: null,
                            })
                          );
                          inputRef.current.value = "";
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <button
                className={`form-submit ca-margin ${
                  checkForDisable() ? "disable-btn" : ""
                }`}
                disabled={checkForDisable()}
                onClick={methods.handleSubmit(onSubmit, onError)}
              >
                {STRING_CONSTANTS.next}
              </button>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonaliseProfile;
