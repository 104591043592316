export const ROUTES_CONSTANTS = {
  index: "/",
  sign_in: "/sign-in",
  sign_up: "/sign-up",
  verify_athlete: "/verify-athlete",
  personalise_profile: "/personalise-profile",
  sport_and_team: "/sport-and-team",
  new_sport: "/new-sport",
  profile_complete: "/complete",
  forgotpwd: "/forgotPassword",
  resetpwd: "/resetPassword",
  terms: "/terms",
  privacy: "/privacy",
  dashboard: "/dashboard",
  test: "/test",
};
