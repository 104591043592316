export const IMAGES = {
  sport_team_1: require("../../assets/images/team/1.png"),
};

export const SVG_IMAGES = {
  disport_logo: require("../../assets/svg/disport_logo.svg").default,
  apple_logo: require("../../assets/svg/apple_logo.svg").default,
  google_logo: require("../../assets/svg/google_logo.svg").default,
  facebook_logo: require("../../assets/svg/facebook_logo.svg").default,
  close_icon: require("../../assets/svg/close_icon.svg").default,
  mail_icon: require("../../assets/svg/mail_icon.svg").default,
  shield_icon: require("../../assets/svg/shield_icon.svg").default,
  person_icon: require("../../assets/svg/person_icon.svg").default,
  sport_icon: require("../../assets/svg/sport_icon.svg").default,
  cross_small_icon: require("../../assets/svg/cross_small_icon.svg").default,
  lock_icon: require("../../assets/svg/lock_icon.svg").default,
  person_input_icon: require("../../assets/svg/person_input_icon.svg").default,
  call_icon: require("../../assets/svg/call_icon.svg").default,
  link_icon: require("../../assets/svg/link_icon.svg").default,
  instagram_icon: require("../../assets/svg/instagram_icon.svg").default,
  trash_icon: require("../../assets/svg/trash_icon.svg").default,
  add_icon: require("../../assets/svg/add_icon.svg").default,
  send_large_icon: require("../../assets/svg/send_large_icon.svg").default,
  error_icon: require("../../assets/svg/error_icon.svg").default,
  facebook_icon: require("../../assets/svg/facebook_icon.svg").default,
  twitter_icon: require("../../assets/svg/twitter_icon.svg").default,
  tiktok_icon: require("../../assets/svg/tiktok_icon.svg").default,
  youtube_icon: require("../../assets/svg/youtube_icon.svg").default,
  remove_cross_icon: require("../../assets/svg/remove_cross_icon.svg").default,
};
