import { ACTION_CONSTANTS } from "../utils/constants/actionConstants";
import { APP_CONSTANTS } from "../utils/constants/appConstants";

export const actionCreators = {
  showLoader: {
    [ACTION_CONSTANTS.TYPE]: ACTION_CONSTANTS.SHOW_LOADER,
    [APP_CONSTANTS.enable_loader]: true,
  },
  hideLoader: {
    [ACTION_CONSTANTS.TYPE]: ACTION_CONSTANTS.HIDE_LOADER,
    [APP_CONSTANTS.enable_loader]: false,
  },
  appLoaded: {
    [ACTION_CONSTANTS.TYPE]: ACTION_CONSTANTS.APP_LOADED,
  },
  logoutSuccess: {
    [ACTION_CONSTANTS.TYPE]: ACTION_CONSTANTS.LOGOUT_SUCCESS,
  },
  fetchedUserDetail: function (userData?: any) {
    return {
      [ACTION_CONSTANTS.TYPE]: ACTION_CONSTANTS.UPDATE_USER,
      [APP_CONSTANTS.user_data]: userData,
    };
  },
  updatePersistedData: function (payload: any) {
    return {
      [ACTION_CONSTANTS.TYPE]: ACTION_CONSTANTS.UPDATE_PERSISTED_DATA,
      payload,
    };
  },
  loginSuccess: function (userData: any, tokenData: any) {
    return {
      [ACTION_CONSTANTS.TYPE]: ACTION_CONSTANTS.LOGIN_SUCCESS,
      [APP_CONSTANTS.user_data]: userData,
      [APP_CONSTANTS.token_data]: tokenData,
    };
  },
  acceptedTerms: function () {
    return {
      [ACTION_CONSTANTS.TYPE]: ACTION_CONSTANTS.ACCEPTED_TERMS,
    };
  },
  acceptedPrivacy: function () {
    return {
      [ACTION_CONSTANTS.TYPE]: ACTION_CONSTANTS.ACCEPTED_PRIVACY,
    };
  },
  updateCreationData: function (userData?: any, registerFunc?: any) {
    return {
      [ACTION_CONSTANTS.TYPE]: ACTION_CONSTANTS.UPDATE_CREATION_DATA,
      [APP_CONSTANTS.user_data]: userData,
      [APP_CONSTANTS.register_athlete_function]: registerFunc,
    };
  },
  errorHandler: function (errorMessage: string) {
    return {
      [ACTION_CONSTANTS.TYPE]: ACTION_CONSTANTS.ERROR_HANDLER,
      [APP_CONSTANTS.error_message]: errorMessage,
    };
  },
  exceptionHandler: function (exception: any) {
    return {
      [ACTION_CONSTANTS.TYPE]: ACTION_CONSTANTS.EXCEPTION_HANDLER,
      [APP_CONSTANTS.exception_object]: exception,
    };
  },
};
