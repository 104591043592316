import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SVG_IMAGES } from "../utils/constants/assets";
// import { COMMON_STYLES } from "../utils/constants/commonStyles";
import { ROUTES_CONSTANTS } from "../utils/constants/routesString";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { actionCreators } from "../actions/actionCreators";
import DisportStepsMenu from "../components/DisportStepsMenu";

function ProfileComplete() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const handleClose = () => {
    if (state?.fromNewSport) {
      navigate(ROUTES_CONSTANTS.new_sport);
    } else {
      navigate(ROUTES_CONSTANTS.sport_and_team);
    }
  };

  useEffect(() => {
    // * prevents going back and takes to register page
    history.pushState(null, document.title, location.href);
    window.addEventListener("popstate", function (event) {
      history.pushState(null, document.title, location.href);
      dispatch(actionCreators.logoutSuccess);
      navigate("/");
    });
  }, []);

  return (
    <div className="tabs-main">
      <DisportStepsMenu step={5} isRegisterComplete />

      <div className="nav-wd-right">
        <div className="wd-100">
          {/* <div className="cross">
            <img src={SVG_IMAGES.close_icon} onClick={handleClose} />
          </div> */}
          <h2>
            {state?.fromNewSport
              ? STRING_CONSTANTS.thanks_for_requesting_new_sport
              : STRING_CONSTANTS.profile_sent_to_admin}
            <span>
              {state?.fromNewSport
                ? STRING_CONSTANTS.new_sport_request_desc
                : STRING_CONSTANTS.profile_verification_desc}
            </span>
          </h2>

          <div className="form-m cant-2">
            {/* <form> */}
            <div className="complete-img">
              <img src={SVG_IMAGES.send_large_icon} />
            </div>
            <Link to={process.env.REACT_APP_LANDING_PAGE_URL || "/"}>
              <button className="form-submit com-btn">{STRING_CONSTANTS.got_it}</button>
            </Link>
            {/* </form> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileComplete;
