export const COLORS = {
  primary_theme: "#43FF86",
  theme_background_color: "#FCF6EB",
  secondary_theme: "#FFFFFF",
  primary_background_color: "#FFFFFF",
  card_background_color: "#FFFFFF",
  white_color: "#FFFFFF",
  error_color: "#EB5757",
  text_color: "#FFFFFF",
  loader_bg: "#FFFFFF",
  light_yellow: "#FCF6EB",
  light_gray: "#F8F9FA",
  transparent: "transparent",
  black: "#000000",
  black_text_rgba: (alpha: any) => `rgba(16, 16, 16, ${alpha})`, // * #101010
  black_rgba: (alpha: any) => `rgba(0,0,0,${alpha})`,
  white_rgba: (alpha: any) => `rgba(255,255,255,${alpha})`,
  grey: "#BDBDBD",
  mid_grey: "#B3B3B3",
  dark_green: "#082D0F",
  settings_grey_bg: "#2E2E2E",
  settings_grey_rgba: (alpha: any) => `rgba(46, 46, 46, ${alpha})`,
  settings_border: "#555655",
  modal_bar: "#F2F2F2",
};
