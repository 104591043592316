const SvgPersonInputIcon = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M17.7541 13.9992C18.9961 13.9992 20.0029 15.0061 20.0029 16.2481V17.1665C20.0029 17.7399 19.8237 18.2989 19.4903 18.7654C17.9445 20.9287 15.4202 22.0004 11.9999 22.0004C8.57891 22.0004 6.05595 20.9282 4.51379 18.7639C4.18182 18.298 4.00342 17.7401 4.00342 17.1681V16.2481C4.00342 15.0061 5.01027 13.9992 6.25229 13.9992H17.7541ZM11.9999 2.00391C14.7613 2.00391 16.9999 4.24248 16.9999 7.00391C16.9999 9.76533 14.7613 12.0039 11.9999 12.0039C9.23845 12.0039 6.99988 9.76533 6.99988 7.00391C6.99988 4.24248 9.23845 2.00391 11.9999 2.00391Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default SvgPersonInputIcon;
