/**
 * Not keeping as ENUM, as sometimes urls need to be structured dynamically
 * And this is not supported by ENUMs
 */

export const GOOGLE_PLACE_API = "https://maps.googleapis.com/";
export const API_END_POINTS = {
  login_url: `login/`,
  verify_otp_url: `verify_otp/`,
  send_otp_url: `send_otp/`,
  verify_email_url: `verify-email/`,
  register_url: `register/`,
  profile_url: `profile/`,
  reset_password_url: `forgot-password/`,
  change_password_url: `change-password/`,
  check_email_url: `check-email/`,

  //   Google apis
  googlePlaceAutocomplete: `${GOOGLE_PLACE_API}maps/api/place/autocomplete/json?components=country:au&key=${"Config.GOOGLE_PLACES_API_KEY"}`,
  apple_transaction: "apple-transaction/",
  google_transaction: "google-transaction/",
  request_sport: "request-sport/",
};
