import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actionCreators } from "../../actions/actionCreators";
import { SVG_IMAGES } from "../../utils/constants/assets";
import { COMMON_STYLES } from "../../utils/constants/commonStyles";
import { ROUTES_CONSTANTS } from "../../utils/constants/routesString";
import SvgMailIcon from "../../assets/SvgComponents/MailIcon";
import SvgShieldIcon from "../../assets/SvgComponents/ShieldIcon";
import SvgPersonIcon from "../../assets/SvgComponents/PersonIcon";
import SvgSportIcon from "../../assets/SvgComponents/SportIcon";
import { StoreType } from "../../store/types";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";

type DisportStepsMenuProps = {
  step: number;
  isRegisterComplete?: boolean;
  handleSaveAthleteFormData?: () => void;
};

function DisportStepsMenu({
  step,
  isRegisterComplete,
  handleSaveAthleteFormData,
}: DisportStepsMenuProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state: StoreType) => state?.UserReducer?.user);

  const activeClassForStep = (activeStep: number) =>
    step <= activeStep ? (step >= activeStep ? "gray" : "") : "success";

  const handleStepNavigation = (stepName: string) => {
    if (isRegisterComplete) {
      dispatch(actionCreators.logoutSuccess);
      navigate("/");
      return;
    }
    let routeToNavigate = ROUTES_CONSTANTS.index;
    switch (stepName) {
      case ROUTES_CONSTANTS.sign_up:
        routeToNavigate = ROUTES_CONSTANTS.sign_up;
        break;
      case ROUTES_CONSTANTS.verify_athlete:
        routeToNavigate = ROUTES_CONSTANTS.verify_athlete;
        break;
      case ROUTES_CONSTANTS.personalise_profile:
        routeToNavigate = ROUTES_CONSTANTS.personalise_profile;
        break;
      case ROUTES_CONSTANTS.sport_and_team:
        routeToNavigate = ROUTES_CONSTANTS.sport_and_team;
        break;
      default:
        routeToNavigate = ROUTES_CONSTANTS.index;
    }

    handleSaveAthleteFormData?.();
    navigate(routeToNavigate);
  };

  const handleTermsAndPrivacy = (legalType: "terms/" | "privacy/") => {
    if (process.env.REACT_APP_LANDING_PAGE_URL) {
      window.open(
        `${process.env.REACT_APP_LANDING_PAGE_URL}${legalType}`,
        "_blank",
        "noreferrer"
      );
    }
  };

  const handleRegisterPage = () => {
    navigate(ROUTES_CONSTANTS.index);
  };

  return (
    <div className="nav-wd-left">
      <div className="nav-wd-logo">
        <img src={SVG_IMAGES.disport_logo} onClick={handleRegisterPage} />

        <div className="btn-tabs">
          <div className="btn-tabs-inner">
            <h2>
              {STRING_CONSTANTS.complete_steps}:<span></span>
            </h2>
            {step !== 5 ? <h2>{step}/4</h2> : <h2>Complete</h2>}
          </div>
          <ul>
            <li className={activeClassForStep?.(1)}>
              <SvgMailIcon className="mail-up" />
              <div
                onClick={() => handleStepNavigation(ROUTES_CONSTANTS.sign_up)}
              >
                {STRING_CONSTANTS.create_account}
                <span>{STRING_CONSTANTS.register_with_email}</span>
              </div>
            </li>
            <li className={activeClassForStep?.(2)}>
              <SvgShieldIcon className="mail-up" />
              <div
                onClick={() => {
                  if (userData.email) {
                    handleStepNavigation(ROUTES_CONSTANTS.verify_athlete);
                  }
                }}
              >
                {STRING_CONSTANTS.verify_identity}
                <span>{STRING_CONSTANTS.attach_social}</span>
              </div>
            </li>
            <li className={activeClassForStep?.(3)}>
              <SvgPersonIcon className="mail-up" />
              <div
                onClick={() => {
                  if (userData.firstName) {
                    handleStepNavigation(ROUTES_CONSTANTS.personalise_profile);
                  }
                }}
              >
                {STRING_CONSTANTS.personalise_profile}
                <span>{STRING_CONSTANTS.register_with_email}</span>
              </div>
            </li>
            <li className={activeClassForStep?.(4)}>
              <SvgSportIcon className="mail-up" />
              <div
                onClick={() => {
                  if (userData.username) {
                    handleStepNavigation(ROUTES_CONSTANTS.sport_and_team);
                  }
                }}
              >
                {STRING_CONSTANTS.team_n_sport}
                <span>{STRING_CONSTANTS.who_u_represent}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <h3>
        <span onClick={() => handleTermsAndPrivacy("terms/")}>
          {STRING_CONSTANTS.tnc}
        </span>{" "}
        |{" "}
        <span onClick={() => handleTermsAndPrivacy("privacy/")}>
          {STRING_CONSTANTS.pp}
        </span>
      </h3>
    </div>
  );
}

export default DisportStepsMenu;
