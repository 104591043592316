import { Toaster } from "react-hot-toast";
import AllRoutes from "./routers/routes";
const App = () => {
  return (
    <>
      <AllRoutes />
      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
};

export default App;
