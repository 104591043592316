import { Link } from "react-router-dom";
import { SVG_IMAGES } from "../utils/constants/assets";
import { ROUTES_CONSTANTS } from "../utils/constants/routesString";
import DisportStepsMenu from "../components/DisportStepsMenu";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";

function RegisterAsAthlete() {
  const handleTermsAndPrivacy = (legalType: "terms/" | "privacy/") => {
    if (process.env.REACT_APP_LANDING_PAGE_URL) {
      window.open(
        `${process.env.REACT_APP_LANDING_PAGE_URL}${legalType}`,
        "_blank",
        "noreferrer"
      );
    }
  };

  return (
    <div className="tabs-main">
      <DisportStepsMenu step={1} />

      <div className="nav-wd-right">
        <div className="wd-100">
          {/* <div className="cross">
            <img src={SVG_IMAGES.close_icon} />
          </div> */}
          <h2>
            {STRING_CONSTANTS.register_as_athlete}{" "}
            <span>{STRING_CONSTANTS.enter_ur_email}</span>
          </h2>
          <h4>{STRING_CONSTANTS.continue_with}</h4>

          <div className="dis-width">
            <a href="">
              <img src={SVG_IMAGES.apple_logo} />
              <span>{STRING_CONSTANTS.apple}</span>
            </a>
            <a href="">
              <img src={SVG_IMAGES.google_logo} />
              <span>{STRING_CONSTANTS.google}</span>
            </a>
            <a href="">
              <img src={SVG_IMAGES.facebook_logo} />
              <span>{STRING_CONSTANTS.facebook}</span>
            </a>
          </div>
          <h5>{STRING_CONSTANTS.or}</h5>
          <h6>
            <Link to={ROUTES_CONSTANTS.sign_up}>{STRING_CONSTANTS.continue_with_email}</Link>
          </h6>
        </div>
      </div>
      <div className="nav-wd-right terms-and-conditions-mobile">
        <h3 style={{ paddingTop: 0 }}>
          <span onClick={() => handleTermsAndPrivacy("terms/")}>
            Terms & Conditions
          </span>{" "}
          |{" "}
          <span onClick={() => handleTermsAndPrivacy("privacy/")}>
            Privacy Policy
          </span>
        </h3>
      </div>
    </div>
  );
}

export default RegisterAsAthlete;
