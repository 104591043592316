export const APP_CONSTANTS = {
  flavour_dev: "DEV",
  flavour_staging: "STAGING",
  flavour_production: "PRODUCTION",

  device_android: "android",
  device_ios: "ios",

  user_data: "user",
  token_data: "token",
  is_toast: "isToast",
  access_token_key: "access",
  refresh_token_key: "refresh",

  data: "data",
  type: "type",
  register_athlete_function: "register_athlete_function",

  enable_loader: "enable_loader",
  error_message: "error_message",
  exception_object: "exception_object",

  splash_screen_timeout: 500,
};
