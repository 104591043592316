const SvgSportIcon = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.0902 4.97048C20.5222 5.48809 20.8809 6.04618 21.1664 6.63102C21.0607 6.7967 20.9354 6.95353 20.7907 7.09824C19.9007 7.98831 18.5523 8.1407 17.5052 7.55541L20.0902 4.97048ZM19.0295 3.90982L16.4446 6.49478C15.8592 5.44775 16.0115 4.09926 16.9016 3.20915C17.0463 3.06446 17.2032 2.93925 17.3688 2.83355C17.9537 3.11899 18.5119 3.47775 19.0295 3.90982ZM20.5234 14.9624C21.9083 12.9768 22.319 10.518 21.7556 8.25177C20.2749 9.64393 18.0399 9.77422 16.418 8.64263L15.3108 9.74983L20.5234 14.9624ZM15.3573 7.58199C14.2257 5.9601 14.3559 3.72511 15.7481 2.24433C13.4818 1.68101 11.0232 2.09177 9.0376 3.4766L14.2502 8.68917L15.3573 7.58199ZM13.1895 9.74983L7.88629 4.4466C6.9534 5.38898 6.29404 6.50024 5.9082 7.68136C7.8206 7.97587 10.3533 8.70379 12.4376 10.5017L13.1895 9.74983ZM11.7283 11.211C11.9169 11.371 12.1012 11.5403 12.2804 11.7195C12.4596 11.8988 12.629 12.0831 12.789 12.2717C14.4185 14.1918 15.0805 16.5472 15.3455 18.3451C15.4062 18.7571 15.4461 19.1398 15.472 19.4813C15.4768 19.5447 15.4791 19.6075 15.4791 19.6697C15.479 21.0224 14.3599 22.0764 12.9815 21.9718C11.0035 21.8218 7.64217 21.2027 5.21972 18.7802C2.79728 16.3578 2.17819 12.9965 2.02815 11.0184C2.02335 10.9551 2.02099 10.8923 2.021 10.8301C2.02112 9.47737 3.14023 8.42342 4.5186 8.52797C4.86011 8.55387 5.24286 8.59376 5.65488 8.6545C7.45279 8.91951 9.80823 9.58151 11.7283 11.211ZM8.03033 13.4695C7.73744 13.1767 7.26256 13.1767 6.96967 13.4695C6.67678 13.7624 6.67678 14.2373 6.96967 14.5302L9.46967 17.0302C9.76256 17.3231 10.2374 17.3231 10.5303 17.0302C10.8232 16.7373 10.8232 16.2624 10.5303 15.9695L8.03033 13.4695ZM16.3186 18.0918C16.0241 16.1794 15.2962 13.6467 13.4983 11.5624L14.2502 10.8105L19.5534 16.1137C18.611 17.0466 17.4997 17.706 16.3186 18.0918Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSportIcon;
