import { useNavigate } from "react-router-dom";
import { IMAGES, SVG_IMAGES } from "../utils/constants/assets";
import { useLocalStorage } from "usehooks-ts";
// import { COMMON_STYLES } from "../utils/constants/commonStyles";
import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import DisportStepsMenu from "../components/DisportStepsMenu";
import { doSignup } from "../network/authRequests";
import { StoreType } from "../store/types";
import { ROUTES_CONSTANTS } from "../utils/constants/routesString";
import { isNotEmpty } from "../utils/globalFunctions";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { API_CONSTANTS } from "../utils/constants/apiConstants";
import { actionCreators } from "../actions/actionCreators";

function AthleteSportAndTeam() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const userData = useSelector((state: StoreType) => state?.UserReducer?.user);

  const [athleteToken, setAthleteToken] = useLocalStorage("token", "");

  const [isLoading, setIsLoading] = useState(false);
  const [selectedTopSport, setSelectedTopSport] = useState<any>(null);

  const createAccount = async (
    fromNewSport?: any,
    handleRequestNewSport?: any
  ) => {
    const attributes = [];
    for (let attribute in userData?.profileImage) {
      attributes.push(attribute);
    }

    setIsLoading(true);
    let payload: any = { userType: "A" };

    if (selectedTopSport) {
      payload[API_CONSTANTS.top_sport] = selectedTopSport;
    }

    Object.keys(userData).map((key: string) => {
      if (isNotEmpty(userData[key]) || attributes.length) {
        if (Array.isArray(userData[key]))
          payload[key] = JSON.stringify(userData[key]);
        else payload[key] = userData[key];
      }
    });

    dispatch(
      doSignup(
        payload,
        (res: any) => {
          setIsLoading(false);
          toast.success(STRING_CONSTANTS.athlete_profile_success);
          if (fromNewSport) {
            setAthleteToken(res?.token?.access);
            handleRequestNewSport(res?.data);
          } else {
            navigate(ROUTES_CONSTANTS.profile_complete);
          }
        },
        (err) => {
          // * error toast
          try {
            const errorMessages = Object.fromEntries([...err]?.values());

            if (errorMessages?.email) {
              toast.error(errorMessages?.email);
            } else if (errorMessages?.mobileNumber) {
              toast.error(errorMessages?.mobileNumber);
            } else {
              toast.error(STRING_CONSTANTS.athlete_profile_error);
            }
          } catch (error) {
            toast.error(STRING_CONSTANTS.athlete_profile_error);
          }
          setIsLoading(false);
        }
      )
    );
  };

  const handleProfileComplete = (e: any) => {
    e.preventDefault();
    createAccount();
  };

  const handlePersonaliseProfile = () => {
    navigate(ROUTES_CONSTANTS.personalise_profile);
  };

  const handleAddNewSport = (e: any) => {
    e.preventDefault();

    dispatch(actionCreators.updateCreationData(null, createAccount));
    navigate(ROUTES_CONSTANTS.new_sport);
  };

  const handleSelectSport = (sport: "NRL" | "AFL") => {
    if (selectedTopSport === sport) {
      setSelectedTopSport(null);
    } else {
      setSelectedTopSport(sport);
    }
  };

  return (
    <div className="tabs-main">
      <DisportStepsMenu step={4} />

      <div className="nav-wd-right">
        <div className="wd-100">
          <div className="cross">
            <img
              src={SVG_IMAGES.close_icon}
              onClick={handlePersonaliseProfile}
            />
          </div>
          <h2>
            {STRING_CONSTANTS.what_sport_u_play}{" "}
            <span>{STRING_CONSTANTS.tell_us_ur_team}</span>
          </h2>

          <div className="form-m">
            <div className="nri-btn">
              <span
                className={selectedTopSport === "NRL" ? "active" : ""}
                onClick={() => handleSelectSport("NRL")}
              >
                NRL
              </span>
              <span
                className={selectedTopSport === "AFL" ? "active" : ""}
                onClick={() => handleSelectSport("AFL")}
              >
                AFL
              </span>
            </div>
            {/* Show this only if no sport is selected */}
            <h6 className="cant-find-text" onClick={handleAddNewSport}>
              {STRING_CONSTANTS.cant_find_team}
            </h6>

            <form>
              <div className="team-text-1">
                <h3>{STRING_CONSTANTS.select_ur_team}</h3>
                <ul>
                  <li>
                    <img src={IMAGES.sport_team_1} />
                    Brisbane Broncos
                  </li>
                  {/* <li className="active">
                    <img src={IMAGES.sport_team_1} />
                    Penrith Panthers
                  </li> */}
                  <li>
                    <img src={IMAGES.sport_team_1} />
                    Penrith Panthers
                  </li>
                  <li>
                    <img src={IMAGES.sport_team_1} />
                    Parramatta Eels
                  </li>
                  <li>
                    <img src={IMAGES.sport_team_1} />
                    Cronulla Sharks
                  </li>
                  <li>
                    <img src={IMAGES.sport_team_1} />
                    Melbourne Storm
                  </li>
                  <li>
                    <img src={IMAGES.sport_team_1} />
                    Brisbane Broncos
                  </li>
                  <li>
                    <img src={IMAGES.sport_team_1} />
                    Canterbury-Bankstown Bulldogs
                  </li>
                  <li>
                    <img src={IMAGES.sport_team_1} />
                    St. George Illawarra Dragons
                  </li>
                  <li>
                    <img src={IMAGES.sport_team_1} />
                    Cronulla Sharks
                  </li>
                  <li>
                    <img src={IMAGES.sport_team_1} />
                    Melbourne Storm
                  </li>
                  <li>
                    <img src={IMAGES.sport_team_1} />
                    Brisbane Broncos
                  </li>
                </ul>
              </div>

              <button
                className={`form-submit ${isLoading ? "disable-btn" : ""}`}
                onClick={handleProfileComplete}
              >
                {STRING_CONSTANTS.submit_profile}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AthleteSportAndTeam;
