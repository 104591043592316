import { isDataPaginating, isNotEmpty, logOnConsole, shouldShowTopLoaderOnListing, } from "../utils/globalFunctions";
import { actionCreators } from "../actions/actionCreators";
import { prepareApiRequest } from "./index";
import { ApiErrorType, ApiResponse, API_HEADERS, API_REQUEST, ListingResponse } from "./collections";

export const flatlistWrapper = (
    url: string,
    typeOfRequest: number,
    nextPageUrl: string,
    body: any,
    params: any,
    path: string,
    successCallback: Function,
    errorCallback: Function,
) => {
    logOnConsole("Body Request", body)
    return (dispatch: Function) => {
        if (shouldShowTopLoaderOnListing(typeOfRequest)) {
            showLoader(true, dispatch);
        }
        if (isDataPaginating(typeOfRequest) && nextPageUrl) {
            url = nextPageUrl;
        }

        prepareApiRequest(
            url,
            API_REQUEST.GET,
            API_HEADERS.TYPE_RAW_DATA,
            params,
            body,
            (response: ApiResponse<ListingResponse<any>>) => {
                let nextPage = response.data.next;
                successCallback(
                    response.data.results,
                    nextPage,
                    response.data.count,
                );
                if (shouldShowTopLoaderOnListing(typeOfRequest)) {
                    showLoader(false, dispatch);
                }
            },
            (errorMessage: string, apiErrors?: ApiErrorType) => handleError(errorMessage, apiErrors, errorCallback, dispatch),
            (exception: any) => handleException(exception, dispatch),
            (shouldRefresh: boolean) => handleAuthenticationFailed(shouldRefresh, dispatch)
        );
    };
};

export const commonApiWrapper = (
    dispatch: Function,
    url: string,
    apiRequestType: string,
    contentType: string,
    path: string | null,
    requestData: any,
    params: any,
    successCallback: Function = () => { },
    errorCallback: Function,
    hideLoader?: boolean
) => {
    showLoader(hideLoader ? false : true, dispatch);
    if (path) {
        url = `${url}${path}/`;
    }
    prepareApiRequest(
        url,
        apiRequestType,
        contentType,
        params,
        requestData,
        (response: ApiResponse<any>) => handleSuccess(response, successCallback, dispatch),
        (errorMessage: string, apiErrors?: ApiErrorType) => handleError(errorMessage, apiErrors, errorCallback, dispatch),
        (exception: any) => handleException(exception, dispatch),
        (shouldRefresh: boolean) => handleAuthenticationFailed(shouldRefresh, dispatch)
    );
};

const showLoader = (shouldShow: boolean, dispatch: Function) => {
    if (shouldShow) {
        dispatch(actionCreators.showLoader);
    } else {
        dispatch(actionCreators.hideLoader);
    }
};

const handleAuthenticationFailed = (shouldRefresh: boolean, dispatch: Function) => {
    if (!shouldRefresh) {
        showLoader(false, dispatch)
        dispatch(actionCreators.logoutSuccess)
    } else {
        showLoader(false, dispatch)
    }

}

const handleSuccess = (response: ApiResponse<any>, successCallback: Function, dispatch: Function) => {
    logOnConsole(JSON.stringify(response))
    showLoader(false, dispatch)
    successCallback?.(response)
};

const handleError = (errorMessage: string, apiErrors: ApiErrorType | undefined, errorCallback: Function, dispatch: Function) => {
    if (apiErrors && Object.keys(apiErrors)?.length >= 1) {
        let errorsMap = new Map<string, string>();
        let cummulativeErrorString = ""
        Object.keys(apiErrors).map((value: string, index: number) => {
            let error = apiErrors[value].join("\\n")
            errorsMap.set(value, error)
            cummulativeErrorString = cummulativeErrorString + error
        })
        if (errorCallback) {
            errorCallback?.(errorsMap)
        } else {
            dispatch(actionCreators.errorHandler(cummulativeErrorString));
        }
        showLoader(false, dispatch)
    } else {
        dispatch(actionCreators.errorHandler(errorMessage));
    }

};

const handleException = (exception: any, dispatch: Function) => {
    if (isNotEmpty(exception)) {
        dispatch(actionCreators.exceptionHandler(exception));
    } else {
        showLoader(false, dispatch)
    }
};
