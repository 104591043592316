import axios, { AxiosError, AxiosResponse } from "axios";
import qs from "qs";
import { GOOGLE_PLACE_API } from "../utils/constants/endPoints";
import { logOnConsole, objToFormData } from "../utils/globalFunctions";
import {
  ApiResponse,
  API_HEADERS,
  API_REQUEST,
  GOOGLE_API_RESPONSE,
  TokenType,
} from "./collections";

/**
 * * Kindly check for env-cmdrc.json file in root directory of project to get the Config
 */

const TIMEOUT_DURATION_IN_MILLIS = 60000;

const defaultInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: TIMEOUT_DURATION_IN_MILLIS,
  validateStatus: function (status: number) {
    return status >= 200 && status < 500;
  },
});

defaultInstance.interceptors.request.use(
  (config: any) => {
    logOnConsole("Pre API Request Config:", config);
    logOnConsole("Pre API Request Config Stringified:", JSON.stringify(config));
    if (config.data) {
      if (config.data instanceof FormData) {
        config.headers[API_HEADERS.CONTENT_TYPE] = API_HEADERS.TYPE_FORM_DATA;
      } else {
        config.headers[API_HEADERS.CONTENT_TYPE] = API_HEADERS.TYPE_RAW_DATA;
      }
    }

    const accessToken = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token") || "")
      : null;
    if (accessToken) {
      config.headers[
        API_HEADERS.AUTHORIZATION
      ] = `${API_HEADERS.TOKEN_TYPE} ${accessToken}`;
    }
    // if (storageInstance.contains(appConstants.token_data)) {
    //     const accessToken: string = storageInstance?.getString(appConstants.token_data)!!;

    //     if (accessToken) {
    //         const parsedAccessToken: TokenType = JSON.parse(accessToken);
    //         config.headers[API_HEADERS.AUTHORIZATION] = `${API_HEADERS.TOKEN_TYPE} ${parsedAccessToken.access}`;
    //     }
    // }

    config.paramsSerializer = (params: any) => {
      return qs.stringify(params, {
        arrayFormat: "repeat", //
        encode: false,
      });
    };
    logOnConsole("Post API Request Config:", config);
    logOnConsole(
      "Post API Request Config Stringified:",
      JSON.stringify(config)
    );
    return config;
  },
  (error: any) => {
    // Do something with request error
    logOnConsole("Request Error:", error);
    return Promise.reject(error);
  }
);

defaultInstance.interceptors.response.use(
  (response: any) => {
    logOnConsole("API Response", response.data);
    logOnConsole("API Response Stringfied ", JSON.stringify(response.data));

    /**Handling for Google Place APIs */
    if (response.config.url?.startsWith(GOOGLE_PLACE_API)) {
      // let data: GoogleApiResponse<PlaceSearchResponse> = response.data

      // let statusCode: number;
      // let error: string = "";
      // if (data.status === GOOGLE_API_RESPONSE.OK) {
      //     statusCode = 200
      // } else {
      //     statusCode = 400
      //     error = data.status
      // }

      // let tempData: ApiResponse<Array<PlaceSearchResponse>> = {
      //     data: data.predictions,
      //     status: {
      //         code: statusCode,
      //         message: error,
      //     }
      // }
      // return tempData
      let data: any = response.data || {};
      data.status = {
        code: response.status,
        message: response.statusText,
      };
      return data;
    } else {
      /**For all the othe APIs */
      let data: any = response.data || {};
      data.status = {
        code: response.status,
        message: response.statusText,
      };
      return data;
    }
  },
  (error: AxiosError) => {
    // Do something with request error
    logOnConsole("Response Error:", error.toJSON());
    return Promise.reject(error);
  }
);

const getRequestData = (data: any, contentType: String) => {
  switch (contentType) {
    case API_HEADERS.TYPE_RAW_DATA:
      return data;
    case API_HEADERS.TYPE_MULTIPART_DATA:
    case API_HEADERS.TYPE_FORM_DATA:
      return objToFormData(data);
  }
  return data;
};
export const prepareApiRequest = (
  url: string,
  apiRequestType: String,
  contentType: String,
  params: any,
  body: any,
  successCallback: Function,
  errorCallback: Function,
  exceptionCallback: Function,
  authenticationFailedCallback: Function
) => {
  requestApi(
    url,
    apiRequestType,
    params,
    getRequestData(body, contentType),
    successCallback,
    errorCallback,
    exceptionCallback,
    authenticationFailedCallback
  );
};

const requestApi = (
  url: string,
  apiRequestType: String,
  params: any,
  data: any,
  successCallback: Function,
  errorCallback: Function,
  exceptionCallback: Function,
  authenticationFailedCallback: Function
) => {
  let promise: Promise<any> | null = null;
  switch (apiRequestType) {
    case API_REQUEST.GET:
      logOnConsole("GET Request");
      promise = defaultInstance.get(url, { params: params });
      break;
    case API_REQUEST.POST:
      logOnConsole("POST Request");
      promise = defaultInstance.post(url, data, { params: params });
      break;
    case API_REQUEST.PATCH:
      logOnConsole("PATCH Request");
      promise = defaultInstance.patch(url, data, { params: params });
      break;
    case API_REQUEST.PUT:
      logOnConsole("PUT Request");
      promise = defaultInstance.put(url, data, { params: params });
      break;
    case API_REQUEST.DELETE:
      logOnConsole("DELETE Request");
      promise = defaultInstance.delete(
        url,
        data || params ? { data: data, params: params } : {}
      );
      break;
  }

  promise!!
    .then((response: ApiResponse<any>) => {
      logOnConsole("Final Response", response);
      logOnConsole("Stringified Final Response", JSON.stringify(response));

      if (response?.status) {
        if (response.status.code >= 200 && response.status.code <= 299) {
          // Success
          successCallback?.(response);
        } else if (response.status.code === 401) {
          // Authentication failed
          authenticationFailedCallback?.(false);
          errorCallback?.(response.status.message, response.errors);
        } else if (response.status.code >= 400 || response.status.code <= 499) {
          // Validation Errors
          errorCallback?.(response.status.message, response.errors);
        } else {
          // All the other errors
          errorCallback?.(response.status.message);
        }
      } else exceptionCallback?.(response);
    })
    .catch((ex: any) => {
      // Handle Exception
      exceptionCallback?.(ex);
    });
};
