import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";

import reducer from "../reducers";

const loggerMiddleware: any = [];

if (
  process.env.NODE_ENV === `development` &&
  process.env.REACT_APP_IS_PRODUCTION &&
  !JSON.parse(process.env.REACT_APP_IS_PRODUCTION)
) {
  // * logs on dev and staging
  const { logger } = require(`redux-logger`);

  loggerMiddleware.push(logger);
}

function configureStore(initialState = {}) {
  const enhancer = applyMiddleware(thunkMiddleware, ...loggerMiddleware);
  return createStore(reducer, initialState, enhancer);
}

const store = configureStore();
export default store;
