import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SocialLinks from "social-links";
import * as yup from "yup";
import { actionCreators } from "../actions/actionCreators";
import SvgCallIcon from "../assets/SvgComponents/CallIcon";
import SvgLinkIcon from "../assets/SvgComponents/LinkIcon";
import SvgPersonInputIcon from "../assets/SvgComponents/PersonInputIcon";
import DisportStepsMenu from "../components/DisportStepsMenu";
import InlineErrors from "../components/InlineErrors";
import { Input } from "../components/Input";
import useYupValidationResolver from "../hooks/useYupValidationResolver";
import { StoreType } from "../store/types";
import { SVG_IMAGES } from "../utils/constants/assets";
import { ROUTES_CONSTANTS } from "../utils/constants/routesString";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import {
  checkForError,
  getFullName,
  getNumberWithIsd,
  getSplitName,
  isNotEmpty,
  socialList,
  validatePhoneNumber,
} from "../utils/globalFunctions";
import { VerifyAthleteForm } from "./types";

const validationSchema = yup.object({
  fullName: yup
    .string()
    .required(STRING_CONSTANTS.required_validation("Full name"))
    .matches(/^([a-zA-Z]+\s)*[a-zA-Z]+$/, "Please enter a valid full name"),
  mobileNumber: yup
    .string()
    .required(STRING_CONSTANTS.required_validation("Mobile number"))
    .min(9, "Mobile number should be atleast 9 digit long"),
});

function VerifyAthlete() {
  const resolver = useYupValidationResolver(validationSchema);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state: StoreType) => state?.UserReducer?.user);
  const socialLinks = new SocialLinks();
  const [errorObj, setErrorObj] = useState<any>({
    fullName: "",
    mobileNumber: "",
  });
  const [ISD, setISD] = useState<string>("+ 61");
  const [socialLinkList, setSocialLinkList] = useState<string[]>([]);

  const { ...methods } = useForm<VerifyAthleteForm>({
    mode: "onChange",
    defaultValues: {
      fullName: "",
      mobileNumber: "",
      socialProfileUrl: "",
    },
    resolver,
  });

  const watchFields = methods?.watch([
    "fullName",
    "mobileNumber",
    "socialProfileUrl",
  ]);

  const saveAthleteFormData = (data: VerifyAthleteForm) => {
    setErrorObj({
      fullName: "",
      mobileNumber: "",
    });
    const { firstName, lastName } = getSplitName(data?.fullName);

    const payload = {
      firstName: firstName,
      lastName: lastName,
      mobileNumber: ISD + data?.mobileNumber,
      socialProfileUrl: socialLinkList?.length ? [...socialLinkList] : [],
    };
    if (validatePhoneNumber(payload.mobileNumber)) {
      payload.mobileNumber = payload.mobileNumber?.replaceAll(" ", "");
      dispatch(actionCreators.updateCreationData(payload));
      // handlePersonaliseProfile();
    } else {
      setErrorObj({
        ...errorObj,
        mobileNumber: STRING_CONSTANTS.mobile_number_invalid,
      });
    }
  };

  const onSubmit: SubmitHandler<VerifyAthleteForm> = (data) => {
    saveAthleteFormData(data);
    if (validatePhoneNumber(ISD + data?.mobileNumber)) {
      handlePersonaliseProfile();
    }
  };

  const submitWithoutNavigation: SubmitHandler<VerifyAthleteForm> = (data) => {
    saveAthleteFormData(data);
  };

  const onError: SubmitErrorHandler<VerifyAthleteForm> = (errors: any, e) => {
    setErrorObj({
      fullName: errors.fullName?.message || "",
      mobileNumber: errors.mobileNumber?.message || "",
    });
  };

  const handleSignup = () => {
    navigate(ROUTES_CONSTANTS.sign_up);
  };

  const handlePersonaliseProfile = () => {
    navigate(ROUTES_CONSTANTS.personalise_profile);
  };

  const addSocialLink = (url: string) => {
    const socialMediaName = socialLinks.detectProfile(url);
    if (socialList.indexOf(socialMediaName) !== -1) {
      const foundUrl = socialLinkList.filter((str) =>
        str.includes(socialMediaName)
      );
      if (foundUrl.length) {
        toast.error(STRING_CONSTANTS.social_url_error(socialMediaName));
      } else if (socialLinkList.length >= 5) {
        toast.error(STRING_CONSTANTS.max_social_url_error);
      } else {
        setSocialLinkList([...socialLinkList, url]);
      }
    } else {
      toast.error(STRING_CONSTANTS.unknown_social_err_msg);
    }
    methods.resetField("socialProfileUrl");
  };

  const removeSocialLink = (url: string) => {
    let arr = [...socialLinkList]?.filter((link: string) => link !== url);
    setSocialLinkList([...arr]);
  };

  const getSocialIcon = (url: string) => {
    const socialMediaName = socialLinks.detectProfile(url);
    if (socialMediaName === socialList[0]) return SVG_IMAGES.instagram_icon;
    if (socialMediaName === socialList[1]) return SVG_IMAGES.facebook_icon;
    if (socialMediaName === socialList[3]) return SVG_IMAGES.twitter_icon;
    if (socialMediaName === socialList[4]) return SVG_IMAGES.tiktok_icon;
    if (socialMediaName === socialList[2]) return SVG_IMAGES.youtube_icon;
    return "";
  };

  const SocialLink = ({ url }: { url: string }) => {
    return (
      <div className="margin-tsd">
        <div className="Container">
          <div className="row">
            <div className="col-md-11 col-10">
              <div className="sdb-top">
                <img
                  src={getSocialIcon(url)}
                  alt=""
                  style={{ height: 42, width: 42 }}
                />
                <input type="text" className="into-style" placeholder={url} />
              </div>
            </div>
            <div className="col-md-1 col-2">
              <button
                type="button"
                className="trash-btn"
                id="removeBtn"
                onClick={() => removeSocialLink(url)}
              >
                <img src={SVG_IMAGES.trash_icon} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const checkForDisable = () => {
    if (!isNotEmpty(watchFields[0]) || !isNotEmpty(watchFields[1])) return true;
    return false;
  };

  const handleChange = (key: "fullName" | "mobileNumber", value: string) => {
    if (key === "fullName") {
      if (value === "" && isNotEmpty(errorObj.fullName))
        setErrorObj({ ...errorObj, fullName: "" });
    } else {
      if (value === "" && isNotEmpty(errorObj.mobileNumber))
        setErrorObj({ ...errorObj, mobileNumber: [] });
    }
  };

  const getData = () => {
    const fullName = getFullName(userData);
    const [isd, mobileNum] = getNumberWithIsd(userData?.mobileNumber);
    if (isNotEmpty(fullName)) methods?.setValue("fullName", fullName);
    if (isNotEmpty(mobileNum)) {
      methods?.setValue("mobileNumber", mobileNum);
      setISD(isd || "+ 61");
    }
    setSocialLinkList(userData?.socialProfileUrl || []);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="tabs-main">
      <DisportStepsMenu
        step={2}
        handleSaveAthleteFormData={methods.handleSubmit(
          submitWithoutNavigation,
          onError
        )}
      />

      <div className="nav-wd-right">
        <div className="wd-100">
          <div className="cross">
            <img src={SVG_IMAGES.close_icon} onClick={handleSignup} />
          </div>
          <h2>
            {STRING_CONSTANTS.verify_athlete}{" "}
            <span>{STRING_CONSTANTS.enter_name_social}</span>
          </h2>

          <div className="form-m">
            <FormProvider {...methods}>
              <Input
                name="fullName"
                type="text"
                placeholder="your full name"
                // leftIcon={SVG_IMAGES.person_input_icon}
                leftIconComponent={<SvgPersonInputIcon />}
                rightIcon={SVG_IMAGES.cross_small_icon}
                rightIconPress={() => {
                  methods.resetField("fullName");
                  handleChange("fullName", "");
                }}
                isError={checkForError(errorObj, "fullName")}
                maxLength={100}
                errorMessage={() => <InlineErrors errors={errorObj.fullName} />}
                onChangeCB={(value) => handleChange("fullName", value)}
              />
              <Input
                name="mobileNumber"
                type="number"
                placeholder="00000000"
                // leftIcon={SVG_IMAGES.call_icon}
                leftIconComponent={<SvgCallIcon />}
                rightIcon={SVG_IMAGES.cross_small_icon}
                rightIconPress={() => {
                  methods.resetField("mobileNumber");
                  handleChange("mobileNumber", "");
                }}
                isError={checkForError(errorObj, "mobileNumber")}
                errorMessage={() => (
                  <InlineErrors errors={errorObj.mobileNumber} />
                )}
                onKeyDown={(e) =>
                  ["+", ".", "e", "E"].includes(e.key) && e.preventDefault()
                }
                isIsd={true}
                ISD={ISD}
                changeISD={(value: string) => setISD(value)}
                onChangeCB={(value) => handleChange("mobileNumber", value)}
              />
              <div className="social-media">
                <h4>{STRING_CONSTANTS.link_ur_social}</h4>

                <span className="social-btn">
                  <Input
                    name="socialProfileUrl"
                    type="text"
                    placeholder="add your social link"
                    // leftIcon={SVG_IMAGES.link_icon}
                    leftIconComponent={<SvgLinkIcon />}
                    rightIcon={SVG_IMAGES.cross_small_icon}
                    rightIconPress={() =>
                      methods.resetField("socialProfileUrl")
                    }
                    extContainerStyle={{ flex: 1 }}
                    // inputStyle={{ marginLeft: 18 }}
                    rightIconContainerStyle={{
                      marginRight: 18,
                    }}
                  />
                  <button
                    type="button"
                    className={`social-btn-1 ${
                      isNotEmpty(watchFields[2]) ? "enable-btn" : "disable-btn"
                    }`}
                    id="addBtn"
                    disabled={!isNotEmpty(watchFields[2])}
                    onClick={() =>
                      addSocialLink(methods.getValues("socialProfileUrl"))
                    }
                  >
                    Add
                  </button>
                </span>
                {socialLinkList?.map?.((item: string, index: number) => (
                  <SocialLink key={index} url={item} />
                ))}
              </div>
              <button
                className={`form-submit abv ${
                  checkForDisable() ? "disable-btn" : ""
                }`}
                disabled={checkForDisable()}
                onClick={methods.handleSubmit(onSubmit, onError)}
              >
                {STRING_CONSTANTS.next}
              </button>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyAthlete;
