export const STRING_CONSTANTS = {
  // app_name: Config.APP_NAME,
  ok_text: "ok",

  required_validation: (name: string) => `${name} is required!`,
  invalid_email_msg: "Please enter a valid email address",
  invalid_pass_msg:
    "Must contain minimum eight characters, at least one letter and one number",
  invalid_pass_special_msg:
    "Must contain minimum eight characters, at least one letter, one number and one special character",

  connection_offline: "Your connection seems Offline",
  connection_online: "You are back online",
  connection_offline_description:
    "Cannot proceed with the operation.\nPlease check your Internet connection.",

  // Permission related strings
  permission_required_text: "Permission Required!",
  settings_text: "Settings",
  cancel_text: "Cancel",
  profile_sent_to_admin: "Profile Request Sent To Admin",
  profile_verification_desc:
    "Verification may take up to 1-3 business days. You will be sent an email once verification is complete.",
  thanks_for_requesting_new_sport: "Thanks For Requesting A New Sport",
  new_sport_request_desc:
    "It seems your sport isn’t covered at the moment we are continually making updates and hope to include your sport in the next update.",
  unknown_social_err_msg:
    "We only accept Facebook, Instagram, Tiktok, Twitter and  Youtube accounts",
  social_url_error: (str: string) => `You have already added ${str}`,
  max_social_url_error: "You have already added maximum social accounts",

  //socials
  instagram: "Instagram",
  facebook: "Facebook",
  twitter: "Twitter",
  youtube: "Youtube",
  tiktok: "Tiktok",

  pwd_special_missing: (keyName: string) =>
    `${keyName} is missing a special character`,
  pwd_capital_missing: (keyName: string) =>
    `${keyName} is missing a capital letter`,
  pwd_number_missing: (keyName: string) => `${keyName} is missing a number`,
  pwd_min_length_missing: (keyName: string) =>
    `${keyName} should be atleast 8 characters long`,
  at_missing: "@ is missing in the email address",
  enter_at_with_email: "Please enter the '@' with email address",
  email_missing_at: "Email address is missing the @ symbol",

  mobile_number_invalid: "Please enter a valid mobile number",
  athlete_profile_success: "Athlete profile submitted successfully!",
  athlete_profile_error: "Couldn't submit profile details for verification!",
  complete_steps: "Complete these steps",
  create_account: "Create an account",
  register_with_email: "Register with your email or social accounts",
  verify_identity: "Verify your identity",
  attach_social: "Attach your social media accounts",
  personalise_profile: "Personalise your profile",
  team_n_sport: "Team and sport selection",
  who_u_represent: "Tell us who you represent",
  tnc: "Terms & Conditions",
  pp: "Privacy Policy",
  press_to_exit: "Press anywhere to exit",
  what_sport_u_play: "What Sports Do You Play?",
  tell_us_ur_team: "Let us know which sport and team you play for",
  cant_find_team: `Can’t find your sport?`,
  select_ur_team: "Select which team you represent",
  submit_profile: "Submit profile for verification",
  enter_ur_team: "Enter your team",
  submit_sport_request: "Submit Sport Request",
  what_we_call_u: "What Should We Call You?",
  face_n_username: `Add a face to the name and enter your desired username, we
  recommend keeping this the same as your other social handles.`,
  suggested_username: "Suggested Usernames",
  ur_social_handles: "Based On Your Social Handles",
  add_pfp: "Add your profile image",
  next: "Next",
  got_it: "Got it, thanks",
  continue_with_email: "Continue with email",
  register_as_athlete: "Register As An Athlete",
  enter_ur_email: "Enter your email below and create a password",
  continue_with: "Continue with...",
  apple: "Apple",
  google: "Google",
  or: "or",
  user_already_exist: "A user with this email already exists",
  verify_athlete: "Verify You Are An Athlete",
  enter_name_social: `Enter your full name below and any links to your social media
  accounts`,
  link_ur_social: "Link your existing social media accounts",
  email_placeholder: "your@email.com",
  new_sport_request_success: "New sport request sent successfully",
  new_sport_request_error:
    "Couldn't submit new sport details for verification!",
  new_sport_error: (keyName: string) => `Please enter a valid ${keyName}`,
};
