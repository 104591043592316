import { Fragment } from "react"
export const Layout = (props: any) => {
    return (
        <Fragment>
            <div className="dashboard">
                <div
                    className="loader"
                    id="full_loader"
                    style={{
                        display: "none",
                        position: "fixed",
                        height: "100vh",
                        width: "100%",
                        top: "0px",
                        left: "0px",
                        backgroundColor: "#0000006e",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                    <img
                        src="/static/loader.gif"
                        width="100px"
                        height="100px"
                        alt=""
                    />
                </div>
                {props.children}
            </div>
        </Fragment>
    )
}