import { STRING_CONSTANTS } from "./constants/stringConstants";
import parsePhoneNumberFromString, {
  isValidPhoneNumber,
} from "libphonenumber-js";
export const isNotEmpty = (data: any) => {
  return (
    data !== null &&
    data !== undefined &&
    data !== "" &&
    (typeof data === "object" ? Object.keys(data).length > 0 : true)
  );
};

export const objToFormData = (rawData: any) => {
  let formData: FormData = new FormData();
  if (rawData && rawData != null && typeof rawData === "object") {
    Object.keys(rawData).map((item, index) => {
      formData.append(item, rawData[item]);
    });
  }
  return formData;
};

export const emptyFunction = () => {};

export const isDataRefreshing = (typeOfRequest: number) => {
  return typeOfRequest === FLATLIST_REQUEST.REFRESH;
};

export const isDataPaginating = (typeOfRequest: number) => {
  return typeOfRequest === FLATLIST_REQUEST.PAGINATION;
};

export const shouldShowTopLoaderOnListing = (typeOfRequest: number) => {
  return typeOfRequest === FLATLIST_REQUEST.FIRST_TIME;
};

export enum FLATLIST_REQUEST {
  FIRST_TIME,
  REFRESH,
  PAGINATION,
}

export const checkForError = (errObj: any, key: string) => {
  if (typeof errObj[key] === "string") return isNotEmpty(errObj[key]);
  else if (Array.isArray(errObj[key])) return errObj[key]?.length !== 0;
  return false;
};

export const getFullName = (obj: any) => {
  let str = "";
  str += obj?.firstName || "";
  if (str !== "" && isNotEmpty(obj?.lastName)) str += " ";
  str += obj?.lastName || "";
  return str;
};

export const getSplitName = (fullName: string) => {
  let str = fullName || "";
  let obj = {
    firstName: str.split(" ").slice(0, -1).join(" "),
    lastName: str.split(" ").slice(-1).join(" "),
  };
  if (!isNotEmpty(obj?.firstName)) {
    obj.firstName = obj.lastName;
    obj.lastName = "";
  }
  return obj;
};

export const getUsernameSuggestions = (username: string) => {
  if (!username) return "";

  const usernameSuggestions: Array<string> = [];

  const randomNumber = Math.round(Math.random() * 100).toLocaleString("en", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  const patternArray = [
    { position: "start", pattern: "Official_", separator: "_" },
    { position: "end", pattern: "_Official", separator: "_" },
    { position: "end", pattern: `_${randomNumber}`, separator: "_" },
    { position: "middle", separator: "." },
  ];

  for (const patternItem of patternArray) {
    let usernameSuggestion;

    if (patternItem.position === "start") {
      usernameSuggestion = `${patternItem.pattern}${username}`;
    } else if (patternItem.position === "middle") {
      usernameSuggestion = username;
    } else {
      // * for end position
      usernameSuggestion = `${username}${patternItem.pattern}`;
    }

    const usernameWithSeparator = usernameSuggestion?.replaceAll(
      " ",
      patternItem.separator
    );

    // * e.g, official_john_smith
    const usernameInLowercase = usernameWithSeparator.toLowerCase();
    usernameSuggestions.push(usernameInLowercase);
  }

  return usernameSuggestions;
};

export const socialList = [
  "instagram",
  "facebook",
  "youtube",
  "twitter",
  "tiktok",
];

export const logOnConsole = (...arg: any) => {
  if (
    process.env.NODE_ENV === `development` &&
    process.env.REACT_APP_IS_PRODUCTION &&
    !JSON.parse(process.env.REACT_APP_IS_PRODUCTION)
  ) {
    console.log(arg);
  }
};

export const passwordCheck = (str: string, keyName: string = "Password") => {
  let arr: string[] = [];

  //check for special characters
  if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str))
    arr.push(STRING_CONSTANTS.pwd_special_missing(keyName));
  //check for capital letter
  if (!/[A-Z]/.test(str))
    arr.push(STRING_CONSTANTS.pwd_capital_missing(keyName));
  //check for number
  if (!/[0-9]/.test(str))
    arr.push(STRING_CONSTANTS.pwd_number_missing(keyName));
  if (str?.length < 8)
    arr.push(STRING_CONSTANTS.pwd_min_length_missing(keyName));

  return arr;
};

export const checkEmail = (str: string) => {
  let arr: string[] = [];
  if (
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      str
    )
  ) {
    const containsAtSymbol = /@/.test(str);
    if (containsAtSymbol) {
      arr.push(STRING_CONSTANTS.invalid_email_msg);
    } else {
      arr.push(STRING_CONSTANTS.email_missing_at);
    }
  }
  return arr;
};

export const validatePhoneNumber = (mobileNum: any) => {
  return isValidPhoneNumber(mobileNum);
};

export const getNumberWithIsd = (mobileNum: any) => {
  if (!mobileNum) {
    return ["", ""];
  }

  // logOnConsole(parsePhoneNumber(mobileNum));
  // logOnConsole(mobileNum, "This is the mobile number u are referring to");
  mobileNum = mobileNum.replace(/[()\s]/g, "");

  // If string starts with +, drop first 3 characters
  if (mobileNum.slice(0, 1) === "+") {
    mobileNum = mobileNum.substring(3);
  }

  if (mobileNum.slice(0, 1) === "0") {
    mobileNum = mobileNum.substring(1);
  }
  // logOnConsole(mobileNum, "This is the mobile number u are referring to 2");

  //this module is used to make country code separate from combined mobile number
  const parsedNumber = parsePhoneNumberFromString(mobileNum)
    ?.format("INTERNATIONAL")
    ?.split(" ");

  const isd = parsedNumber?.shift() || "+61";

  // //Added for checking length particularily based on AUS
  // if (mobileNum.length === 10 || mobileNum === 9) {
  // 	return [isd, mobileNum];
  // }

  return [isd, mobileNum];
};
