import React, { CSSProperties } from "react";
interface propType {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  wrapperStyle?: CSSProperties;
  iconStyle?: React.CSSProperties;
  image?: string;
  disabled?: boolean;
  alt?: string;
}
const ImageIcon = (props: propType) => {
  return props?.image ? (
    <button
      onClick={props?.onClick}
      style={props?.wrapperStyle}
      disabled={props?.disabled || false}
    >
      <img src={props?.image} alt={props?.alt || ""} style={props?.iconStyle} />
    </button>
  ) : null;
};
export default ImageIcon;
