import { actionCreators } from "../actions/actionCreators";
import { API_END_POINTS } from "../utils/constants/endPoints";
import { API_HEADERS, API_REQUEST } from "./collections";
// import { API_HEADERS, API_REQUEST, LoginRequestType } from "./collections";
import { commonApiWrapper } from "./requests";
const auth_api = process.env.REACT_APP_AUTH_API;

export const doSignup = (
  data: any,
  successCallback: (res: any) => void,
  errorCallback: (err: any) => void
) => {
  return (dispatch: Function) =>
    commonApiWrapper(
      dispatch,
      auth_api + API_END_POINTS.profile_url,
      API_REQUEST.POST,
      API_HEADERS.TYPE_FORM_DATA,
      null,
      data,
      null,
      (res: any) => {
        dispatch(actionCreators.loginSuccess(res?.data, res?.token));
        successCallback?.(res);
      },
      errorCallback
    );
};
export const checkForEmail = (
  data: any,
  successCallback: (res: any) => void,
  errorCallback?: (err: any) => void
) => {
  return (dispatch: Function) =>
    commonApiWrapper(
      dispatch,
      auth_api + API_END_POINTS.check_email_url,
      API_REQUEST.POST,
      API_HEADERS.TYPE_FORM_DATA,
      null,
      data,
      null,
      (res: any) => successCallback?.(res),
      errorCallback as never
    );
};
export const requestNewSport = (
  data: any,
  successCallback: (res: any) => void,
  errorCallback: (err: any) => void
) => {
  return (dispatch: Function) =>
    commonApiWrapper(
      dispatch,
      auth_api + API_END_POINTS.request_sport,
      API_REQUEST.POST,
      API_HEADERS.TYPE_FORM_DATA,
      null,
      data,
      null,
      (res: any) => {
        // dispatch(actionCreators.loginSuccess(res?.data, res?.token));
        successCallback?.(res);
      },
      errorCallback
    );
};
//Login
export const doLogin = () => {
  return null;
};
// export const doLogin = (
//     data: LoginRequestType,
//     successCallback: (res: any) => void,
//     errorCallback: (err: any) => void,
// ) => {
//     return (dispatch: Function) =>
//         commonApiWrapper(
//             dispatch,
//             API_END_POINTS.login_url,
//             API_REQUEST.POST,
//             API_HEADERS.TYPE_FORM_DATA,
//             null,
//             data,
//             null,
//             successCallback,
//             errorCallback,
//         );
// };
