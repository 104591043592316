export enum ACTION_CONSTANTS {
  TYPE = "type",
  CLASS_DETAIL_FETCHED = "CLASS_DETAIL_FETCHED",

  SHOW_LOADER = "SHOW_LOADER",
  HIDE_LOADER = "HIDE_LOADER",
  ERROR_HANDLER = "ERROR_HANDLER",
  EXCEPTION_HANDLER = "EXCEPTION_HANDLER",

  ACCEPTED_PRIVACY = "ACCEPTED_PRIVACY",
  ACCEPTED_TERMS = "ACCEPTED_TERMS",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  UPDATE_USER = "UPDATE_USER",
  FETCH_PROFILE = "FETCH_PROFILE",
  APP_LOADED = "APP_LOADED",
  LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
  UPDATE_PERSISTED_DATA = "UPDATE_PERSISTED_DATA",
  UPDATE_CREATION_DATA = "UPDATE_CREATION_DATA",
}
