import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { SVG_IMAGES } from "../utils/constants/assets";
// import { COMMON_STYLES } from "../utils/constants/commonStyles";
import { ROUTES_CONSTANTS } from "../utils/constants/routesString";
import DisportStepsMenu from "../components/DisportStepsMenu";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { NewSportForm } from "./types";
import { useState } from "react";
import { API_CONSTANTS } from "../utils/constants/apiConstants";
import { useDispatch, useSelector } from "react-redux";
import { requestNewSport } from "../network/authRequests";
import {
  checkForError,
  isNotEmpty,
  logOnConsole,
} from "../utils/globalFunctions";
import { Input } from "../components/Input";
import InlineErrors from "../components/InlineErrors";
import SvgSportIcon from "../assets/SvgComponents/SportIcon";
import SvgPersonInputIcon from "../assets/SvgComponents/PersonInputIcon";
import { StoreType } from "../store/types";
import useYupValidationResolver from "../hooks/useYupValidationResolver";
import { newSportValidationSchema } from "../utils/validationSchema";

function NewSport() {
  const resolver = useYupValidationResolver(newSportValidationSchema);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  // const userData = useSelector((state: StoreType) => state?.UserReducer?.user);
  const registerAthleteFunction = useSelector(
    (state: StoreType) => state?.UserReducer?.register_athlete_function
  );

  const [isLoading, setIsLoading] = useState(false);
  const [errorObj, setErrorObj] = useState<any>({
    sportRequested: "",
    teamRequested: "",
  });

  const { ...methods } = useForm<NewSportForm>({
    mode: "onChange",
    defaultValues: {
      sportRequested: "",
      teamRequested: "",
    },
    resolver,
  });

  const watchFields = methods?.watch(["sportRequested", "teamRequested"]);

  const handleComplete = () => {
    navigate(ROUTES_CONSTANTS.profile_complete, {
      state: { fromNewSport: true },
    });
  };

  const handleRequestNewSport = (userData: any) => {
    const payload = {
      [API_CONSTANTS.sport_requested]: watchFields[0],
      [API_CONSTANTS.team_requested]: watchFields[1],
    };

    if (userData?.id) {
      // * send new sport request
      dispatch(
        requestNewSport(
          payload,
          () => {
            setIsLoading(false);
            toast.success(STRING_CONSTANTS.new_sport_request_success);
            handleComplete();

            localStorage.clear();
          },
          (err) => {
            setIsLoading(false);
            toast.error(STRING_CONSTANTS.new_sport_request_error);
            logOnConsole("error requesting new sport->", err);
          }
        )
      );
    } else {
      // * register athlete api failed
    }
  };

  const onSubmit: SubmitHandler<NewSportForm> = async (data) => {
    setErrorObj({
      sportRequested: "",
      teamRequested: "",
    });

    try {
      setIsLoading(true);
      // * fromNewSport: true
      await registerAthleteFunction(true, handleRequestNewSport);
    } catch (error) {
      logOnConsole("error on submitting new sport request data->", error);
      setIsLoading(false);
    }
  };

  const onError: SubmitErrorHandler<NewSportForm> = (errors: any, e) => {
    setErrorObj({
      sportRequested: errors.sportRequested?.message || "",
      teamRequested: errors.teamRequested?.message || "",
    });
  };

  const handleChange = (
    key: "sportRequested" | "teamRequested",
    value: string
  ) => {
    if (key === "sportRequested") {
      if (value === "" && isNotEmpty(errorObj.sportRequested))
        setErrorObj({ ...errorObj, sportRequested: "" });
    } else {
      if (value === "" && isNotEmpty(errorObj.teamRequested)) {
        setErrorObj({ ...errorObj, teamRequested: "" });
      }
    }
  };

  const checkForDisable = () => {
    return !isNotEmpty(watchFields[0]) || !isNotEmpty(watchFields[1]);
  };

  const handleSportAndTeam = () => {
    navigate(ROUTES_CONSTANTS.sport_and_team);
  };

  return (
    <div className="tabs-main">
      <DisportStepsMenu step={4} />

      <div className="nav-wd-right">
        <div className="wd-100">
          <div className="cross">
            <img src={SVG_IMAGES.close_icon} onClick={handleSportAndTeam} />
          </div>
          <h2>
            {STRING_CONSTANTS.what_sport_u_play}{" "}
            <span>{STRING_CONSTANTS.tell_us_ur_team}</span>
          </h2>

          <div className="form-m cant-2">
            <form>
              <FormProvider {...methods}>
                <div className="cant-find your-sport">
                  <Input
                    name="sportRequested"
                    type="text"
                    placeholder="your sport"
                    leftIconComponent={<SvgSportIcon />}
                    rightIcon={SVG_IMAGES.cross_small_icon}
                    rightIconPress={() => {
                      methods.resetField("sportRequested");
                      handleChange("sportRequested", "");
                    }}
                    isError={checkForError(errorObj, "sportRequested")}
                    maxLength={100}
                    errorMessage={() => (
                      <InlineErrors errors={errorObj?.sportRequested} />
                    )}
                    onChangeCB={(value) =>
                      handleChange("sportRequested", value)
                    }
                  />
                </div>

                <div className="cant-find">
                  <h3>{STRING_CONSTANTS.enter_ur_team}</h3>
                </div>

                <div className="cant-find your-team">
                  <Input
                    name="teamRequested"
                    type="text"
                    placeholder="your team"
                    leftIconComponent={<SvgPersonInputIcon />}
                    rightIcon={SVG_IMAGES.cross_small_icon}
                    rightIconPress={() => {
                      methods.resetField("teamRequested");
                      handleChange("teamRequested", "");
                    }}
                    isError={checkForError(errorObj, "teamRequested")}
                    maxLength={100}
                    errorMessage={() => (
                      <InlineErrors errors={errorObj?.teamRequested} />
                    )}
                    onChangeCB={(value) => {
                      handleChange("teamRequested", value);
                    }}
                  />
                </div>
                <button
                  className={`form-submit ${isLoading ? "disable-btn" : ""} ${
                    checkForDisable() ? "disable-btn" : ""
                  }`}
                  disabled={checkForDisable()}
                  onClick={methods.handleSubmit(onSubmit, onError)}
                >
                  {STRING_CONSTANTS.submit_sport_request}
                </button>
              </FormProvider>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewSport;
