import { useNavigate } from "react-router-dom";
import { SVG_IMAGES } from "../utils/constants/assets";
// import { COMMON_STYLES } from "../utils/constants/commonStyles";
import { ROUTES_CONSTANTS } from "../utils/constants/routesString";
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { SignUpFrom } from "./types";
import { Input } from "../components/Input";
import * as yup from "yup";
import useYupValidationResolver from "../hooks/useYupValidationResolver";
import { useEffect, useState } from "react";
import InlineErrors from "../components/InlineErrors";
import {
  checkEmail,
  checkForError,
  isNotEmpty,
  passwordCheck,
} from "../utils/globalFunctions";
import { STRING_CONSTANTS } from "../utils/constants/stringConstants";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../actions/actionCreators";
import { StoreType } from "../store/types";
import DisportStepsMenu from "../components/DisportStepsMenu";
import SvgMailIcon from "../assets/SvgComponents/MailIcon";
import SvgLockIcon from "../assets/SvgComponents/LockIcon";
import { checkForEmail } from "../network/authRequests";
import { toast } from "react-hot-toast";

const validationSchema = yup.object({
  email: yup
    .string()
    .email(STRING_CONSTANTS.invalid_email_msg)
    .required(STRING_CONSTANTS.required_validation("Email")),
  password: yup
    .string()
    .required(STRING_CONSTANTS.required_validation("Password"))
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,}$/,
      "Invalid Password"
    ),
});

function SignUp() {
  const resolver = useYupValidationResolver(validationSchema);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const userData = useSelector((state: StoreType) => state?.UserReducer?.user);
  const [errorObj, setErrorObj] = useState<any>({
    email: [],
    password: [],
  });

  const { ...methods } = useForm<SignUpFrom>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
    resolver,
  });

  const watchFields = methods?.watch(["email", "password"]);

  const saveAthleteFormData = (data: SignUpFrom) => {
    setErrorObj({
      email: [],
      password: [],
    });
    const payload = {
      email: data?.email,
      password: data?.password,
    };
    dispatch(actionCreators.updateCreationData(payload));
  };

  const onSubmit: SubmitHandler<SignUpFrom> = (data) => {
    setErrorObj({
      email: [],
      password: [],
    });
    const emailData = {
      email: methods.getValues("email"),
    };
    localStorage.clear();

    dispatch(
      checkForEmail(emailData, (res: any) => {
        if (res?.data?.emailExists) {
          toast.error(STRING_CONSTANTS.user_already_exist);
        } else {
          if (!errorObj?.email?.length) {
            saveAthleteFormData(data);
            handleVerifyAthlete();
          }
        }
      })
    );
  };

  const submitWithoutNavigation: SubmitHandler<SignUpFrom> = (data) => {
    if (!errorObj?.email?.length) {
      saveAthleteFormData(data);
    }
  };

  const onError: SubmitErrorHandler<SignUpFrom> = (errors: any, e) => {
    const err = {
      email: checkEmail(watchFields[0]),
      password: passwordCheck(watchFields[1]),
    };
    setErrorObj({ ...err });
  };

  const handleRegister = () => {
    navigate(ROUTES_CONSTANTS.index);
  };

  const handleVerifyAthlete = () => {
    navigate(ROUTES_CONSTANTS.verify_athlete);
  };

  const checkForDisable = () => {
    if (!isNotEmpty(watchFields[0]) || !isNotEmpty(watchFields[1])) return true;
    return false;
  };

  const handleChange = (key: "email" | "password", value: string) => {
    if (key === "email") {
      if (value !== "") {
        setErrorObj({ ...errorObj, email: checkEmail(value) });
      } else if (value === "" && isNotEmpty(errorObj.email)) {
        setErrorObj({ ...errorObj, email: [] });
      }
    } else {
      if (value === "" && errorObj.password.length !== 0)
        setErrorObj({ ...errorObj, password: [] });
    }
  };

  const getData = () => {
    if (isNotEmpty(userData?.email))
      methods?.setValue("email", userData?.email);
    if (isNotEmpty(userData?.password))
      methods?.setValue("password", userData?.password);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="tabs-main">
        <DisportStepsMenu
          step={1}
          handleSaveAthleteFormData={methods.handleSubmit(
            submitWithoutNavigation,
            onError
          )}
        />

        <div className="nav-wd-right">
          <div className="wd-100">
            <div className="cross">
              <img src={SVG_IMAGES.close_icon} onClick={handleRegister} />
            </div>
            <h2>
              {STRING_CONSTANTS.register_as_athlete}{" "}
              <span>{STRING_CONSTANTS.enter_ur_email}</span>
            </h2>

            <div className="form-m">
              <FormProvider {...methods}>
                <Input
                  name="email"
                  type="email"
                  placeholder={STRING_CONSTANTS.email_placeholder}
                  leftIconComponent={<SvgMailIcon />}
                  rightIcon={SVG_IMAGES.cross_small_icon}
                  rightIconPress={() => {
                    methods.resetField("email");
                    handleChange("email", "");
                  }}
                  isError={checkForError(errorObj, "email")}
                  errorMessage={() => <InlineErrors errors={errorObj.email} />}
                  onChangeCB={(value) => handleChange("email", value)}
                />
                <Input
                  name="password"
                  type="password"
                  placeholder="Create password"
                  leftIconComponent={<SvgLockIcon />}
                  rightIcon={SVG_IMAGES.cross_small_icon}
                  rightIconPress={() => {
                    methods.resetField("password");
                    handleChange("password", "");
                  }}
                  isError={checkForError(errorObj, "password")}
                  errorMessage={() => (
                    <InlineErrors errors={errorObj.password} />
                  )}
                  onChangeCB={(value) => handleChange("password", value)}
                />
                <button
                  className={`form-submit ${
                    checkForDisable() ? "disable-btn" : ""
                  }`}
                  disabled={checkForDisable()}
                  onClick={methods.handleSubmit(onSubmit, onError)}
                >
                  {STRING_CONSTANTS.continue_with_email}
                </button>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
