export const API_CONSTANTS = {
  data_key: "data",
  token_key: "token",
  docs_key: "docs",
  total_key: "total",
  limit_key: "limit",
  page_key: "page",
  pages_key: "pages",
  status_key: "status",
  access_token_key: "access",
  refresh_token_key: "refresh",
  user_data_key: "data",

  next_page_url_key: "next",
  previous_page_url_key: "previous",
  count_key: "count",

  email: "email",
  first_name: "first_name",
  last_name: "last_name",
  top_sport: "topSport",
  sport_requested: "sportRequested",
  team_requested: "teamRequested",
};
