const SvgCallIcon = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M7.7716 2.43899L8.84819 2.09498C9.85701 1.77263 10.9349 2.29358 11.3669 3.31232L12.2266 5.33967C12.6013 6.22312 12.3934 7.26203 11.7126 7.90815L9.81832 9.70616C9.9352 10.7816 10.2967 11.8406 10.9029 12.8832C11.509 13.9257 12.266 14.7905 13.1739 15.4776L15.4491 14.7189C16.3115 14.4313 17.2507 14.7618 17.7797 15.539L19.0121 17.3495C19.627 18.2529 19.5164 19.4993 18.7534 20.2653L17.9356 21.0862C17.1217 21.9033 15.9592 22.1997 14.8838 21.8643C12.3449 21.0723 10.0106 18.7211 7.88083 14.8107C5.74796 10.8945 4.99521 7.57189 5.62258 4.84289C5.88658 3.69457 6.70409 2.78009 7.7716 2.43899Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
export default SvgCallIcon;
