import React from "react";
interface ISDPickerProps {
  value?: string;
  updateValue?: (value: string) => void;
  isError?: boolean;
  leftIconColor?: string;
}
const ISDPicker = (props: ISDPickerProps) => {
  return (
    <div className="select-wd">
      <select
        name="isd"
        id="isd"
        className={`select-1 ${
          props?.isError ? "error" : props?.leftIconColor
        }`}
        value={props?.value || "+ 61"}
        onChange={(e) => props?.updateValue?.(e.target.value)}
      >
        <option value="+ 61">+ 61</option>
        <option value="+ 62">+ 62</option>
        <option value="+ 63">+ 63</option>
        <option value="+ 64">+ 64</option>
      </select>
    </div>
  );
};
export default ISDPicker;
