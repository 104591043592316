import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./router";
import { SignIn } from "../components/signin";
import SignUp from "../container/SignUp";
import { ForgotPassword } from "../components/forgotpassword";
import { ResetPassword } from "../components/resetpassword";
import { Dashboard } from "../components/dashboard";
// import { Test } from "../test";
import { ROUTES_CONSTANTS } from "../utils/constants/routesString";
import UnauthRouter from "./unauthRouter";
import { Layout } from "../container/layout";
import RegisterAsAthlete from "../container/RegisterAsAthlete";
import VerifyAthlete from "../container/VerifyAthlete";
import PersonaliseProfile from "../container/PersonaliseProfile";
import AthleteSportAndTeam from "../container/AthleteSportAndTeam";
import ProfileComplete from "../container/ProfileComplete";
import NewSport from "../container/NewSport";
// import NotFound from 'pages/notFound';
export default class AllRoutes extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Layout>
            <Routes>
              {/* <UnauthRouter exact path={ROUTES_CONSTANTS.signup} component={SignUp} />
                            <UnauthRouter exact path={ROUTES_CONSTANTS.signin} component={SignIn} />
                            <UnauthRouter exact path={ROUTES_CONSTANTS.forgotpwd} component={ForgotPassword} />
                            <UnauthRouter exact path={`${ROUTES_CONSTANTS.resetpwd}/:uid/:token`} component={ResetPassword} /> */}

              <Route
                path={ROUTES_CONSTANTS.dashboard}
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              {/* <PrivateRoute path={ROUTES_CONSTANTS.dashboard} element={<Dashboard />} /> */}
              <Route
                path={ROUTES_CONSTANTS.index}
                element={<RegisterAsAthlete />}
              />
              <Route path={ROUTES_CONSTANTS.sign_up} element={<SignUp />} />
              <Route
                path={ROUTES_CONSTANTS.verify_athlete}
                element={<VerifyAthlete />}
              />
              <Route
                path={ROUTES_CONSTANTS.personalise_profile}
                element={<PersonaliseProfile />}
              />
              <Route
                path={ROUTES_CONSTANTS.sport_and_team}
                element={<AthleteSportAndTeam />}
              />
              <Route path={ROUTES_CONSTANTS.new_sport} element={<NewSport />} />
              <Route
                path={ROUTES_CONSTANTS.profile_complete}
                element={<ProfileComplete />}
              />
              <Route path={ROUTES_CONSTANTS.sign_in} element={<SignIn />} />
              {/* <Route path={ROUTES_CONSTANTS.test} element={<Test />} /> */}
              {/* <PrivateRoute exact path={'/settings/:id'} component={Settings} /> */}
              {/* <PrivateRoute exact path={"/*"} component={NotFound} /> */}
            </Routes>
          </Layout>
        </Router>
      </React.Fragment>
    );
  }
}
